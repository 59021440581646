import { default as React, useEffect, useContext } from 'react'
import { Grid, Paper, Skeleton, Typography } from '@mui/material'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import { ApiGateway, FeatureFlags } from '../config/config'
import FileListControl from '../form/FileListControl'

export default function AuthorizationFilesCard(props = {}) {
  const roleDetails = useContext(RoleContext)

  const { mfrProfile, authorization, readOnly, noTable } = props

  console.log(authorization)
  useEffect(() => {
    
  }, [authorization])

  const isMfiAdminOrAbove = roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN])
  const editable = !readOnly && isMfiAdminOrAbove && FeatureFlags.facAuthQseUpdates ? authorization.workflowStatus === 'DRAFT' || (isMfiAdminOrAbove && authorization.isHistorical === true) : authorization.workflowStatus === 'DRAFT'

  return (
    <RoleContext.Consumer>
      {roleCtx => (

        <Paper elevation={1} sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', margin: '1em auto', padding: "1em" }}>
          <Grid container columnGap={1} rowGap={1} spacing={1}>

            {/* Latest Letter */}
            <Grid item xs={12}>
              <Typography fontWeight='bold' variant="body2" color="text.secondary">Latest authorization letter</Typography>
              <br />
              {props.loading && <Skeleton />}
              {!props.loading && <FileListControl
                listFilesUrl={`${ApiGateway.authorizations}/${authorization.id}/files`}
                showLastModified={true}
                showActions={editable}
                enableUpload={editable}
                enableDownload={true}
                getFileUrlServiceAddr={`${ApiGateway.authorizations}/${authorization.id}/files/`}
                getPutFileUrlServiceAddr={`${ApiGateway.authorizations}/${authorization.id}/files/new-file-url`}
                removeFileUrl={`${ApiGateway.authorizations}/${authorization.id}/files`}
                altFileNameColumnLabel='Original File Name'
                noTable={noTable}
              />}
            </Grid>

            {/* Past Letters */}
            {/* <Grid item xs={4}>
              <Typography fontWeight='bold' variant="body2" color="text.secondary">Latest authorization letter</Typography>
              <br />
              <FileListControl
                showLastModified={true}
                showActions={true}
                enableUpload={true}
                enableDownload={true}
              />
            </Grid> */}

          </Grid>
        </Paper>
      )}
    </RoleContext.Consumer>
  )
}