import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useIsMounted } from '../utils/ReactUtils.js'
import { 
  MaterialReactTable, 
  useMaterialReactTable,
 } from 'material-react-table'
import { Stack, Checkbox, Box, Grid, Paper, IconButton, Button, Typography, Radio, Slide, Collapse } from '@mui/material'
import axios from 'axios'
import { Link } from 'react-router-dom';
import { getRequestConfig } from '../auth/auth.js'
import { ApiGateway } from '../config/config.js';
import CustomTabPanel from '../form/CustomTabPanel.js'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ChevronLeft, Edit, EditOutlined } from '@mui/icons-material'
import ReferencesTable from './ReferencesTable.js'
import bus from '../utils/EventBus.js'
import Event from '../utils/EventNames.js'
import { capitalizeFirst } from '../utils/TextUtils.js'
import useMediaQuery from '@mui/material/useMediaQuery';

export default function ListContacts(props = { searchFilter: undefined, rowsPerPage: 15, pageIndex: 0 }) {
  const isMounted = useIsMounted()
  const desktop = useMediaQuery('(min-width:1000px)');
  // const mobile = useMediaQuery('(max-width:999px)');

  const [dataRows, setDataRows] = useState([])
  const [ownershipReferencesRows, setOwnershipReferencesRows] = useState([])
  const [manufacturerReferencesRows, setManufacturerReferencesRows] = useState([])
  const [selectedRowId, setSelectedRowId] = useState(null)
  const [infoPanelDisplay, setInfoPanelDisplay] = useState('none')
  const [infoPanelWidth, setInfoPanelWidth] = useState('0%')
  const [selectedTab, setSelectedTab] = useState(0)
  const [infoPanelLoading, setInfoPanelLoading] = useState(false)
  const [columnOrder, setColumnOrder] = useState([]);
  const [columns, setColumns] = useState([]);

  const reassignReferenceToEmail = async (requestConfig, parentType, mfrOrOwnContactId, reassignTo) => {
    const basePath = `${ApiGateway.facilityManagement}/${parentType}-contact/${mfrOrOwnContactId}`
    const body = {
      action: 'reassign',
      reassignTo
    }
    await axios.post(basePath, body, requestConfig)
  }

  const maybeDisplaySuccess = (errors, rows, action) => {
    if (!errors.length) {
      bus.emit(Event.ALERT, {
        duration: 3000,
        showClose: true,
        style: 'success',
        text: `Successfully ${action}ed ${rows.length} reference${rows.length > 1 ? 's' : ''}`,
        jsx: <span style={{ width: '100%' }} className='contents-center'>
          Fetching the latest data. Please wait...
        </span>
      })
    }
  }

  const maybeDisplayErrors = (errors, parentType, action) => {
    if (errors.length) {
      bus.emit(Event.ALERT, {
        duration: 5000,
        style: 'error',
        text: `Failed to ${action} ${errors.length} reference${errors.length > 1 ? 's' : ''}:`,
        jsx: <Stack gap={1}>
          {errors.map(e => <Stack>
            <Typography variant='body'>{e.row?.[capitalizeFirst(parentType)]?.[`${parentType}Name`]} - {e.row?.ContactType?.name}</Typography>
            <Typography variant='body2'>({e.error.message})</Typography>
          </Stack>)}
        </Stack>
      })
    }
  }

  const deleteMfrOrOwnContact = async (requestConfig, parentType, parentId, mfrOrOwnContactId) => {
    const basePath = `${ApiGateway.facilityManagement}/${parentType}/${parentId}/contacts/${mfrOrOwnContactId}`
    await axios.delete(basePath, requestConfig)
  }

  const onSubmitReassign = async (parentType, rowsToReassign, reassignToContact, cb) => {
    bus.emit(Event.SHOW_PROGRESS_MODAL)
    const errors = []
    try {
      const requestConfig = await getRequestConfig()
      const _rowsToReassign = [...rowsToReassign]
      const completed = _rowsToReassign.length

      while (_rowsToReassign.length) {
        bus.emit(Event.PROGRESS_MODAL_PROGRESS, {
          completed,
          progress: completed - _rowsToReassign.length
        })
        const thisRow = _rowsToReassign.pop()
        
        try {
          await reassignReferenceToEmail(
            requestConfig,
            parentType,
            thisRow.id,
            reassignToContact.email
          )
        } catch (err) {
          errors.push({
            error: err,
            row: thisRow
          })
        }
      }

    } catch (err) {

    } finally {
      const newDataRows = await getContactList(true)
      if(newDataRows.find(ndr => ndr.id === selectedRowId)) {
        reloadReferences(selectedRowId)
      } else {
        setSelectedRowId(null)
      }
      
      bus.emit(Event.HIDE_PROGRESS_MODAL)
      cb()
      maybeDisplayErrors(errors, parentType, 'reassign')
      maybeDisplaySuccess(errors, rowsToReassign, 'reassign')
    }
  }

  const onSubmitDelete = async (parentType, rowsToDelete, cb) => {
    bus.emit(Event.SHOW_PROGRESS_MODAL)
    const errors = []
    let thisRow
    try {
      const requestConfig = await getRequestConfig()
      const _rowsToDelete = [...rowsToDelete]
      const completed = _rowsToDelete.length

      while (_rowsToDelete.length) {
        bus.emit(Event.PROGRESS_MODAL_PROGRESS, {
          completed,
          progress: completed - _rowsToDelete.length
        })
        thisRow = _rowsToDelete.pop()
        const mfrOrOwnContactId = thisRow.id
        const parentId = thisRow?.[capitalizeFirst(parentType)].uuid
        try {
          await deleteMfrOrOwnContact(
            requestConfig,
            parentType,
            parentId,
            mfrOrOwnContactId
          )
        } catch (err) {
          errors.push({
            error: err,
            row: thisRow
          })
        }
      }

    } catch (err) {

    } finally {
      const newDataRows = await getContactList(true)
      if(newDataRows.find(ndr => ndr.id === selectedRowId)) {
        reloadReferences(selectedRowId)
      } else {
        setSelectedRowId(null)
      }
      bus.emit(Event.HIDE_PROGRESS_MODAL)
      cb()

      maybeDisplayErrors(errors, parentType, 'remove')
      maybeDisplaySuccess(errors, rowsToDelete, 'remov')
    }
  }

  const getReferencesRows = useCallback(async (rowId) => {
    const row = dataRows.find(dr => dr.id === rowId)
    if(!row) return
    
    const requestConfig = await getRequestConfig()
    const basePath = `${ApiGateway.facilityManagement}/contact/search/custom`
    const search = {
      Contact: {
        filters: {
          id: row?.id,
        }
      },
      OwnershipContact: { attributes: ['id'] },
      ManufacturerContact: { attributes: ['id'] },
      ContactType: { filters: { isInternal: props.isInternal }, attributes: ['id', 'name', 'isInternal'] },
      Ownership: { attributes: ['ownershipName', 'uuid', 'ownershipId'] },
      Manufacturer: { attributes: ['manufacturerName', 'uuid', 'manufacturerId'] },
    }
    const { data } = await axios.post(basePath, search, requestConfig)
    return data.results?.length ? data.results[0] : {}
  }, [dataRows])

  const reloadReferences = useCallback(async (rowId) => {
    setInfoPanelLoading(true)
    const referencesRows = await getReferencesRows(rowId ?? selectedRowId)
    if(referencesRows?.OwnershipContacts?.length) {
      setOwnershipReferencesRows(referencesRows.OwnershipContacts.filter(oc => oc.ContactType?.isInternal === props.isInternal))
    }
    if(referencesRows?.ManufacturerContacts?.length) {
      setManufacturerReferencesRows(referencesRows.ManufacturerContacts.filter(oc => oc.ContactType?.isInternal === props.isInternal))
    }
    setInfoPanelLoading(false)
  }, [selectedRowId, getReferencesRows])

  const handleSelectRow = useCallback(async (row) => {
    if (row?.original?.id === selectedRowId) {
      setSelectedRowId(null)
      setOwnershipReferencesRows([])
      setManufacturerReferencesRows([])
    } else {
      setSelectedRowId(row?.original?.id)
      reloadReferences(row?.original?.id)
    }
  }, [
    selectedRowId,
    reloadReferences
  ])

  const listColumnDef = useMemo(() => {
    const cols = [
      {
        accessorKey: 'email',
        header: 'Email',
        editable: false,
        filterVariant: 'text',
        grow: true
      },
      {
        accessorKey: 'name',
        header: 'Name',
        grow: true,
        editable: false,
        filterVariant: 'text',
      },
      {
        accessorKey: 'role',
        header: 'System Role',
        editable: false,
        filterVariant: 'text',
        grow: true,
        Cell: ({ row }) => row.original.User?.UserRoles?.[0]?.Role?.roleName
      },
    ]

    if(props.editMode) {
      cols.splice(0,0, {
        accessorKey: 'radio',
        header: '',
        size: 30,
        grow: false,
        enableSorting: false,
        editable: false,
        Filter: () => null,
        Cell: ({ row }) => {
          return <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Radio
              sx={{ padding: '1px' }}
              size='small'
              checked={row.original.id === selectedRowId}
              onClick={(event) => {
                handleSelectRow(row)
              }}
            />
          </div>
        }
      })
    }

    return cols
  }, [
    handleSelectRow,
    selectedRowId,
    props.editMode
  ])

  useEffect(() => {
    if(!props.editMode) {
      setSelectedRowId(null)
      setOwnershipReferencesRows([])
      setManufacturerReferencesRows([])
    }
  }, [props.editMode])

  useEffect(() => {
    setColumnOrder(listColumnDef.map((column) => column.id));
    setColumns(listColumnDef)
  }, [
   listColumnDef
  ]);

  const getContactList = async (forceReload) => {
    if (!forceReload && props.cachedContacts !== null && props.cachedContacts.length) {
      setDataRows(props.cachedContacts)
      return props.cachedContacts
    } else {
      if (props.startLoading) props.startLoading()

      const requestConfig = await getRequestConfig()
      let basePath, response, data
      basePath = `${ApiGateway.facilityManagement}/contact/search/custom`
      const search = props.searchFilter || {}
      response = await axios.post(basePath, search, requestConfig)
      setDataRows(response.data.results)
      if (props.cacheContacts) {
        props.cacheContacts(response.data.results)
      }
      if (props.stopLoading) props.stopLoading()
      return response.data.results
    }
  }

  useEffect(() => {
    if (isMounted()) {
      getContactList()
    }
  }, [])

  const renderBottomToolbar = useMemo(() => {
    // return true
    return desktop || selectedRowId === null ? true : false
  }, [selectedRowId, desktop])

  const rowSelected = useMemo(() => {
    return selectedRowId !== null ? true : false
  }, [selectedRowId])

  const table = useMaterialReactTable({
    layoutMode: 'grid',
    columnFilterModeOptions: [
      'fuzzy',
      'greaterThan',
      'lessThan'
    ],
    columns,
    data: dataRows,
    enableColumnActions: false,
    enableColumnFilters: props.showFilters,
    initialState: {
      showColumnFilters: true,
      pagination: {
        pageIndex: props.pageIndex ?? 0,
        pageSize: props.rowsPerPage ?? 15
      },
      sorting: [
        {
          id: 'email',
          asc: true,
        },
      ],
    },
    state: {
      columnOrder
    },
    enablePagination: true,
    enableSorting: true,
    enableBottomToolbar: true,
    enableEditing: false,
    muiTableContainerProps: {
      sx: {
        minHeight: selectedRowId >= 0 && !desktop ? 0 : 300,
        width: '100%',
        maxHeight: 600,
        overflowY: 'auto'
      }
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        if(props.editMode) handleSelectRow(row)
      },
      sx: selectedRowId !== null && !desktop && row.original.id !== selectedRowId ? {
        display: 'none'
      } : {
        cursor: props.editMode ? 'pointer' : undefined,
      },
    }),
    muiTableBodyCellProps: {
      sx: {
        fontSize: '.82rem',
        padding: '4px !important'
      }
    },
    renderBottomToolbar: renderBottomToolbar ? undefined : <Button onClick={() => handleSelectRow({ original: { id: selectedRowId } })}><ChevronLeft />Return to list</Button>,
    renderTopToolbar: false,
  });


  useEffect(() => {
    if (rowSelected) {
      setInfoPanelDisplay('block')
    } else {
      setInfoPanelWidth('0%')
      setTimeout(() => setInfoPanelDisplay('none'), 210)
    }
  }, [rowSelected])

  useEffect(() => {
    if (infoPanelDisplay === 'block') {
      const width = desktop ? '44%' : '100%'
      setInfoPanelWidth(width)
    }
  }, [infoPanelDisplay, desktop])

  const tabChange = (event, toTab) => {
    setSelectedTab(toTab)
  }

  const selectedUser = (dataRows.find(dr => dr.id === selectedRowId) ?? {})
  const user = {...selectedUser?.User, ...selectedUser }

  return (
    <div className='App'>
      <Paper sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '98%', margin: '1em auto' }}>
        <div className={`${desktop ? 'contents-left' : 'flex-rows'} flex-gap-xs`} style={{ width: '99%' }}>
          {/* Table Section */}
          <div style={{ width: rowSelected && desktop ? '56%' : '100%', transition: `width .3s linear` }}>
            <MaterialReactTable
              table={table}
            />
          </div>

          <div style={{ display: infoPanelDisplay, width: infoPanelWidth, transition: `width .25s linear`, maxHeight: '98vh' }}>
            <Box
              sx={{
                padding: 2,
                border: '1px solid #ccc',
                borderRadius: 1,
                boxShadow: 2,
                backgroundColor: '#f5f5f5',
              }}
            >
              <Tabs value={selectedTab} onChange={tabChange}>
                <Tab label='Ownership References' id={`tab-0`}></Tab>
                <Tab label='Manufacturer References' id={`tab-1`}></Tab>
              </Tabs>
              <CustomTabPanel selectedTab={selectedTab} thisTab={0}>
                <ReferencesTable
                  isInternal={props.isInternal}
                  loading={infoPanelLoading}
                  reloadReferences={reloadReferences}
                  parentType='ownership'
                  rows={ownershipReferencesRows}
                  user={user}
                  onSubmitReassign={onSubmitReassign}
                  onSubmitDelete={onSubmitDelete}
                />
              </CustomTabPanel>
              <CustomTabPanel selectedTab={selectedTab} thisTab={1}>
                <ReferencesTable
                  isInternal={props.isInternal}
                  loading={infoPanelLoading}
                  reloadReferences={reloadReferences}
                  parentType='manufacturer'
                  rows={manufacturerReferencesRows}
                  user={user}
                  onSubmitReassign={onSubmitReassign}
                  onSubmitDelete={onSubmitDelete}
                />
              </CustomTabPanel>
            </Box>
          </div>

        </div>
      </Paper>
    </div>
  )

}